<template>
    <v-card>
        <v-card-title primary-title>
            <v-icon left large>mdi-map-marker-radius-outline</v-icon>
            <span class="headline">Asignar Repartidor</span>
            <v-spacer></v-spacer>


            <v-dialog v-model="dialogEditar" scrollable persistent max-width="600px">
                <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on" depressed @click="nuevoItem">
                      <v-icon left>mdi-plus</v-icon> Nuevo</v-btn>
                </template>
                
                <v-card>
                    <v-card-title>
                      <span class="title">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-select
                            @change="$v.editedItem.idrepartidor.$touch()"
                            @blur="$v.editedItem.idrepartidor.$touch()"
                            :error-messages="repartidorErrors"
                            v-model="$v.editedItem.idrepartidor.$model"
                            :items="repartidores"
                            item-text="nombre"
                            item-value="idusuario"
                            menu-props="auto"
                            label="Selecciona al repartidor"
                            single-line
                            prepend-icon="mdi-face-outline"
                            required

                          ></v-select>

                        </v-col>
                        <v-col cols="12" md="12">
                          <v-text-field
                            label="Pedido"
                            v-model.trim="$v.codigo.$model"
                            :error-messages="codigoErrors"
                            @change="$v.codigo.$touch()"
                            @blur="$v.codigo.$touch()"
                            @keypress.enter="agregaCodigo"
                            hint="escanea cada pedido"
                            prepend-icon="mdi-barcode-scan"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-chip v-for="item in editedItem.pedidos" :key="item.id"
                            v-model="item.activo"
                            class="ml-2"
                            close
                            color="primary"
                            dark
                            @click:close="quitaCodigo(item)"
                          >
                            {{ item.id }}
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12">
                        <v-menu
                          v-model="menu2"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.fecha"
                              label="Fecha para surtir"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.fecha"
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn class="error" dark @click="cerrarDialog" depressed>Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="success" dark @click="grabar" depressed>
                            <v-icon left>mdi-checkbox-marked-circle</v-icon>
                            <span>Grabar</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>

              </v-dialog>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col class="pb-0 pt-0 " cols="12" md="4">
             
              <v-select
                  v-model="filtro_idrepartidor"
                  :items="repartidores"
                  item-text="nombre"
                  item-value="idusuario"
                  menu-props="auto"
                  label="Selecciona al repartidor"
                  hide-details
                  prepend-icon="mdi-face-outline"
                  single-line
                  @change="getDataFromApi"
                  @click:clear="clearFiltroRepartidor"
                  clearable
              ></v-select>
              
            </v-col>
            <v-col class="pb-0 pt-0 " cols="12" md="2">
              <v-menu
                v-model="dialog_fecha_inicio"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fecha_desde"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fecha_desde"
                  @input="dialog_fecha_inicio = false"
                ></v-date-picker>
              </v-menu>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers"
                :items="salidas"
                :loading="isLoading"
                :options.sync="options"
                :server-items-length="total_salidas"
                :items-per-page="20"
                item-key="id"
                no-data-text="No se encontraron registros"
                loading-text="Buscando registros"
                :must-sort="true"
                @click:row="itemSelected"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-arrow-left',
                  nextIcon: 'mdi-arrow-right',
                  'items-per-page-options': [20,35,50]
                }"
              >
              </v-data-table> 
            </v-col>
          </v-row>
        </v-card-text>

        <DialogEspera :dialogEspera="dialogEspera"></DialogEspera>

        <SnackbarMensajes v-bind:sb="sb"></SnackbarMensajes>
    </v-card>
</template>

<script>
import platform from 'platform-detect'
import ApiService from '@/services/ApiService.js'
import SnackbarMensajes from '@/components/SnackbarMensajes.vue'
import DialogEspera from '@/components/DialogEspera.vue'

import { validationMixin } from 'vuelidate'
import { required, minLength, numeric } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  name: 'RepVentas',
  mixins: [validationMixin],
  components: { SnackbarMensajes, DialogEspera },
  data: () => ({
    menu2: false,
    isLoading: false,
    salidas: [],
    total_salidas: 0,
    options: {
      sortBy: ['id'],
      sortDesc: [true]
    },
    headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Fecha', value: 'fecha', sortable: false },
        { text: 'Repartidor', value: 'repartidor.nombre', sortable: false },
        { text: 'Pedidos', value: 'pedidos', align: 'start', sortable: false },
    ],
    dialogEditar: false,
    repartidores: [],
    filtro_pedido: '',
    filtro_idrepartidor: '',
    dialog_fecha_inicio: false,
    dialog_fecha_hasta: false,
    fecha_desde: '',
    codigo: '',
    editedItem: {
        id: -1,
        pedidos: [],
        idrepartidor: null,
        fecha: '',
    },
    defaultItem: {
        id: -1,
        pedidos: '',
        idrepartidor: null,
        fecha: '',
    },
    sb: {
      mostrar: false,
      mensaje: '',
      color: 'success',
      timeout: 3000,
      multiline: false,
    },
    dialogEspera: false,
  }),
  validations: {
    codigo: { required, minLength: minLength(2), numeric },
    editedItem: {
        idrepartidor: { required },
        pedidos: { required, minLength: minLength(1) },
    }
  },
  watch: {
    options: {
      handler(){
        this.getDataFromApi();
      }
    },
    fecha_desde: {
      handler(){
        this.getDataFromApi();
      }
    }
  },
  computed: {
    canPrint() {
        return !(platform.ios && platform.pwa);

    },
    isDesktop() {
        return platform.desktop
    },
    formTitle() {
        const title = `Nueva captura`;
        return title
    },
    codigoErrors () {
      const errors = []
      if (!this.$v.codigo.$dirty) return errors
      !this.$v.codigo.numeric && errors.push('Solo se permiten códigos numéricos.')
      !this.$v.codigo.minLength && errors.push('Pedido incorrecto')
      return errors
    },
    repartidorErrors () {
      const errors = []
      if (!this.$v.editedItem.idrepartidor.$dirty) return errors
      !this.$v.editedItem.idrepartidor.required && errors.push('Obligatorio.')
      return errors
    },

  },
  methods: {
    itemSelected(item,options){
      this.codigo = '';
      this.editando = true;      
      this.editedItem = Object.assign({}, item);
      this.editedItem.pedidos = item.pedidos_array.map((elitem) => {
        return { id: elitem, activo: true};
      });
      this.dialogEditar = true;
    },

    listado_pedidos() {
      let cadena = '';
      this.editedItem.pedidos.forEach( (item, idx, arr) => {
        cadena = cadena + `${item.id} `;
      });
      return cadena.trim();
    },
    quitaCodigo(item){
      const idx = this.editedItem.pedidos.indexOf(item);
      if(idx >= 0){
        this.editedItem.pedidos.splice(idx,1);
      }
    },
    agregaCodigo(){
      this.$v.codigo.$touch();
      if(this.$v.codigo.$invalid){
        this.showMensaje(`Captura codigo correctamente`,'error',true);
        return false;
      }

      let ya = false;
      this.editedItem.pedidos.forEach( (item, idx, arr) => {
        if(item.id == this.codigo){
          ya = true;
        }
      });

      if(!ya) this.editedItem.pedidos.push({id: this.codigo, activo: true});
      this.codigo = '';

    },
    clearFiltroRepartidor(event){
        this.filtro_idrepartidor = null;
        this.getDataFromApi();
    },

    grabar: function (){
      this.$v.editedItem.$touch()
      if(this.$v.editedItem.idrepartidor.$invalid){
        this.showMensaje(`Debes indicar el repartidor`,'error',true)
      }
      if(this.$v.editedItem.pedidos.$invalid){
        this.showMensaje(`Debes escanear al menos un pedido`,'error',true)
      }
      if(this.$v.editedItem.$invalid) return false;

      const data = {
        pedidos: this.listado_pedidos(),
        idrepartidor: this.editedItem.idrepartidor,
        fecha: this.editedItem.fecha,
      }

      this.dialogEspera = true;
      ApiService.grabaSalida(this.$store.getters.token, this.editedItem.id, data)
          .then(response => {
              // 201 => Created :: 200 => OK Updated
              //const checkStatus = this.editedItem.id == -1 ? 201 : 200;
              if(response.status == 200 || response.status == 201){
                this.showMensaje(`Salida grabada ID: ${response.data.id}`,'success',false)
                this.dialogEditar = false;
                this.editedItem = Object.assign({}, this.defaultItem)
                this.getDataFromApi();
              }else{
                  this.showMensaje(`Error al grabar ${response.status} - ${response.statusText}`,'error',true)
              }
          })
          .catch(error => {
            this.dialogEspera = false
            this.showMensaje(`Error al grabar ${error.response.status} - ${error.response.statusText}`,'error',true)
          })
          .finally(() => {
            this.dialogEspera = false
          })
    },
    nuevoItem(){
      this.codigo = '';
      this.editando = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.pedidos = [];
      this.editedItem.fecha = moment().format('YYYY-MM-DD');
      this.$v.editedItem.$reset;
      this.dialogEditar = true;
    },
    cerrarDialog() {
          this.dialogEditar = false
    },
    showMensaje(mensaje,color,multiline) {
      this.sb.mensaje = mensaje
      this.sb.multiline = multiline
      this.sb.color = color
      this.sb.mostrar = true
    },
    getCatalogos() {
      this.dialogEspera = true
      ApiService.getRepartidores(this.$store.getters.token)
        .then(response => {
              if (response.status == 200) {
                  this.repartidores = response.data;
              } else {
                  this.showMensaje(
                      `Error al obtener repartidores ${response.status} - ${response.statusText} `,
                      'error', true)
              }
              this.dialogEspera = false;

          }).catch(error => {
              if (error.response) this.showMensaje(
                  `Error al obtener listado XXX ${error.response.status} - ${error.response.statusText}`,
                  'error', true)
              else this.showMensaje(`Error al obtener XXX listado ${error.message}`, 'error', true)
              this.dialogEspera = false
            })
            .finally(() => (this.dialogEspera = false))
    },
    getDataFromApi() {
        this.isLoading = true
        if(!this.filtro_idrepartidor) this.filtro_idrepartidor = null;
        let filtros = {
          idrepartidor: this.filtro_idrepartidor,
          fecha: this.fecha_desde,
        }
        
        ApiService.getSalidas(this.$store.getters.token,this.options,filtros)
          .then(response => {
            if(response.status == 200){
              this.salidas = response.data;
              this.total_salidas = parseInt(response.headers['x-pagination-total-count']);
              this.isLoading = false;
            }else{
              this.showMensaje(`Error al obtener listado ${response.status} - ${response.statusText} `,'error',true)
            }
          })
          .catch(error => {
            this.showMensaje(`Error al obtener listado ${error.response.status} - ${error.response.statusText}`,'error',true)
            this.isLoading = false
          })
          .finally(() => (this.isLoading = false))
    },
  },  
  mounted() {
    this.fecha_desde = moment().format('YYYY-MM-DD');
    this.getCatalogos();
    this.getDataFromApi();
  },
  created() {
  }
}
</script>
